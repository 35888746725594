import React from 'react'
import { Buttons, Close } from './Buttons'
import { FaTimes } from "react-icons/fa";
import './PageTitle.css'
import { FaRegWindowClose } from "react-icons/fa";
import { MdArrowBack } from "react-icons/md";
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
const PageNav = ({ pagetitle }) => {
  const navigate = useNavigate();
  return (
    <div className='page-nav d-flex align-items-center'>
      <div className='Button'>
        <Button className='button' onClick={() => navigate(-1)} ><MdArrowBack className='userEdit icon-edit' /></Button>
      </div>
      <div className='nav-list'>{pagetitle}</div>
    </div>
  )
}
const PageTitle = ({ PageTitle }) => {

  return (
    <div className='py-2 fw-bold'>{PageTitle}</div>
  )
}
const PageHead = ({ pagehead, onClick }) => {

  return (
    <div className='create-head' >
      <div>{pagehead}</div>
      <div className="ms-auto FaRegWindowClose">
        <Close lable={<><FaRegWindowClose className='closebtn' /></>} onClick={onClick} />
      </div>
    </div>
  )
}
const PageBottom = () => {
  return (
    <div className='text-end py-3 ' >
      <span className='mx-2'><Buttons lable={<>Save & New</>} /></span>
      <span className='mx-2'><Buttons lable={<>Save</>} /></span>

    </div>
  )
}
export { PageTitle, PageHead, PageBottom, PageNav }
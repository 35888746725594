import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  Spinner,
  Alert,
  Button,
} from "react-bootstrap";
import * as XLSX from "xlsx";
import { AiOutlineUpload } from "react-icons/ai";
import { TextInputForm, DropDownUI, Calender } from "../components/Forms";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const types = [
  {
    label: "PEPSI",
    value: "pepsi",
  },
  {
    label: "BRITANIA",
    value: "britannia",
  },
  {
    label: "COLGATE",
    value: "colgate",
  },
];
const ExcelUpload = () => {
  const navigate = useNavigate();
  const [excelData, setExcelData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [formData, setFormData] = useState({
    js_data: "",
    productname: "",
  });
  console.log("formData", formData);
  const handleChange = (e, fieldName) => {
    const value = e.target ? e.target.value : e.value;
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };

  useEffect(() => {
    // Listen for network changes
    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  // Function to convert Excel to JSON format
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setLoading(true);
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0]; // Get the first sheet
        const sheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(sheet); // Convert to JSON
        setExcelData(jsonData);
        setFormData((prevFormData) => ({
          ...prevFormData,
          js_data: JSON.stringify(jsonData),
        }));
        setLoading(false);
        toast.success("Excel file uploaded successfully!");
      };
      reader.onerror = () => {
        setError("Failed to read file.");
        setLoading(false);
        toast.error("Error reading the file!");
      };
      reader.readAsArrayBuffer(file);
    } else {
      setError("No file selected.");
      toast.error("No file selected!");
    }
  };

  const [userData, setUserData] = useState([]);
  console.log("userData", userData);
  const fetchData = async () => {
    try {
      // Construct a JSON object with the necessary data
      const payload = {
        excel_data: JSON.parse(formData.js_data), // JSON string of Excel data
        productname: formData.productname,
        userid: localStorage.getItem("userid"),
      };
  
      // Send the request with the JSON payload
      const response = await fetch(
        "https://apivsagency.barbikan.in/mis/create",
        {
          method: "POST",
          headers: {
            Authorization: `${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload), // Convert payload to JSON
        }
      );
  
      // Check if the response status is OK (200)
      if (response.status === 200) {
        const responseData = await response.json();
  
        // Check the response data for success status
        if (responseData.status === 200) {
          // Display success alert
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Manager Excel file uploaded successfully!",
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "my-ok-button-class", // Add your custom class for styling
            },
          });
  
          setUserData(responseData); // Update user data with response data
        } else {
          throw new Error(responseData.msg); // Handle error based on response message
        }
      } else {
        // Handle non-200 response status
        throw new Error(`Failed to fetch data: ${response.status} ${response.statusText}`);
      }
    } catch (error) {
      console.error("Error fetching report data:", error.message);
      // Optionally, show an alert for error handling
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.message,
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "my-ok-button-class", // Add your custom class for styling
        },
      });
    }
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchData();
  };
  useEffect(() => {
    if (!localStorage.getItem("userid")) {
      navigate("/login")
    }
  }, [])
  return (
    <div>
      <Container fluid>
        <div className="main my-3">
          <Row>
            <Col lg="12" md="12" xs="12">
              <div className="page-nav py-3 h5">Manager Upload EXCEL</div>
            </Col>
            <Col lg={12}>
              <div className="justify-content-center d-flex">
                <label htmlFor="file-upload" className="file-upload-label">
                  <AiOutlineUpload size={24} className="upload-icon" />
                  <span>Upload Excel File</span>
                </label>
                <input
                  type="file"
                  id="file-upload"
                  onChange={handleFileUpload}
                  accept=".xlsx, .xls"
                  style={{ display: "none" }}
                />
              </div>
            </Col>
            <Col lg="3" md="12" xs="12" className="py-3">
              <DropDownUI
                optionlist={types}
                placeholder="productname"
                labelname="productname"
                name="productname"
                value={formData.productname}
                onChange={(updatedFormData) =>
                  setFormData({
                    ...formData,
                    productname: updatedFormData.productname,
                  })
                }
              ></DropDownUI>
            </Col>
            <Col lg={3} md="12" xs="12" className="py-5">
              <Button className="excelsubmit" onClick={handleSubmit}>
                Submit
              </Button>
            </Col>
          </Row>

          {loading && (
            <div className="d-flex justify-content-center mt-3">
              <Spinner animation="border" />
              {!isOnline && (
                <Alert variant="warning" className="ml-2">
                  Network is down. Please wait until it reconnects.
                </Alert>
              )}
            </div>
          )}

          {error && (
            <Alert variant="danger" className="mt-3">
              {error}
            </Alert>
          )}
          <ToastContainer position="top-center" autoClose={3000} />
          {/* {isOnline && excelData.length > 0 && (
            <div
              style={{
                overflow: "auto",
                maxHeight: "780px",
                maxWidth: "1880px",
              }}
              className="mt-2"
            >
              <Table striped bordered hover>
                <thead>
                  <tr>
                    {Object.keys(excelData[0]).map((key) => (
                      <th key={key}>{key}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {excelData.map((row, index) => (
                    <tr key={index}>
                      {Object.values(row).map((cell, i) => (
                        <td key={i}>{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )} */}
        </div>
      </Container>
    </div>
  );
};

export default ExcelUpload;



import React from 'react'
import { PDFViewer } from '@react-pdf/renderer';
import CollectionWorkPdf from './CollectionWorkPdf';
const CollectionPdf = () => {
    return (
        <PDFViewer height={"1000"} width={"100%"}>
            <CollectionWorkPdf/>
        </PDFViewer>
    )
}

export default CollectionPdf
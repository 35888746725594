
import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import { useNavigate, Navigate } from 'react-router-dom';
import { Buttons, ClickButton } from '../components/Buttons';
import { PageNav } from '../components/PageTitle';
import { useLocation } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { TextInputForm, DropDownUI, Calender } from '../components/Forms';
import moment from "moment/moment";
import axiosRequest from '../service/api';
import { FaUserEdit } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import { DataGrid } from '@mui/x-data-grid';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
const paymentstatus = [
    { label: "Paid", value: "Paid" },
    { label: "Pending", value: "Pending" },
    { label: "Partial", value: "Partial" },
];
const product = [
    { label: "PEPSI", value: "pepsi" },
    { label: "BRITANNIA", value: "britannia" },
    { label: "COLGATE", value: "colgate" },
    { label: "All", value: "all" },
];
const roll = [
    {
        "label": "Delivery Man",
        "value": "deliveryagent"
    },
    {
        "label": "Sales Man",
        "value": "salesagent"
    },
]
const personid = [
    {
        "label": "Sales@01",
        "value": "sales@01"
    },
    {
        "label": "Delivery@12",
        "value": "delivery@12"
    },
]
const Report = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        date: '',
        product: '',
        roll: '',
        personid: '',
        payment_status:'',
    });
    const [personId, setPersonId] = useState([]);
    const [loading, setLoading] = useState(false);
    const [reportsDataArray, setReportsDataArray] = useState([]);
    console.log("reportsDataArray", reportsDataArray);
    console.log("formData", formData);
    const handleChange = (e, fieldName) => {
        const value = e.target ? e.target.value : e.value;

        setFormData({
            ...formData,
            [fieldName]: value
        });
    }
    const setLabel = (value, field) => {
        setFormData({
            ...formData,
            [field]: moment(value).format("YYYY-MM-DD"), // Update the specified field in formData with the provided value
        });
    };


    const reportsData = async () => {
        setLoading(true)
        const { date, roll, personid, product,payment_status } = formData;
        const headers = {
            Authorization: `${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
        };

        try {
            setReportsDataArray([])
            const response = await axiosRequest(
                'get',
                'reports/pendingdata',
                null,
                { date, role: roll, loginId: personid, productname: product,payment_status:payment_status },
                { headers }
            );
            setReportsDataArray(response.data)
            setLoading(false)
        } catch (error) {
            console.log(error);
        }
        finally {
            setLoading(false)
        }
    };

    const downloadExcel = () => {
        // Filter the data to include only the required fields
        const filteredData = reportsDataArray.map(({ invoice_date,invoice_number,beat_code,beat_code_name,customer_code,customer_name,delivery_man_login_id,date,cuso, delivery_man, customer_id, product_name, qty, amount, amount_paid, remaining_balance }) => ({
            "InvoiceNumber":invoice_number,
            "InvoiceDate":invoice_date,
            "CustomerCode":customer_code,
            "CustomerName":customer_name,   
            "SalesMan": delivery_man,
            "SalesManId":delivery_man_login_id,
            "BeatCode":beat_code,
            "BeatCodeName":beat_code_name,
            // "deliverydate":date,
            
            "Amount": remaining_balance
        }));

        // Create a new workbook
        const wb = XLSX.utils.book_new();

        // Convert filtered data to worksheet
        const ws = XLSX.utils.json_to_sheet(filteredData);

        // Append the worksheet to the workbook
        XLSX.utils.book_append_sheet(wb, ws, 'ReportsData');

        // Write workbook to a binary string
        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

        // Save the file
        const blob = new Blob([wbout], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, 'reports_data.xlsx');
    };

    const fetchUsersByRole = async () => {
        try {
            const role = `${formData.roll}`;
            const headers = {
                Authorization: `${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
            };
            const response = await axiosRequest(
                "get",
                "user/filterbyrole",
                null,
                { role },
                { headers }
            );
            if (Array.isArray(response.users)) {
                const Login_id_array = response.users.map(user => ({
                    label: user.login_id,
                    value: user.login_id,
                }));
                setPersonId(Login_id_array);
            } else {
                console.log("No users found with the specified role.");
            }
        } catch (error) {
            console.error("Error fetching users by role:", error);
        }
    };

    useEffect(() => {
        fetchUsersByRole();
    }, [formData.roll]);

    useEffect(() => {
        setFormData((prev) => ({
            ...prev, // Spread the previous state
            personid: '' // Update the personid field to an empty string
        }));
    }, [formData.roll]);
    const columns = [
        { field: 'delivery_man_login_id', headerName: 'Deliery Man Id', width: 200 },
        { field: 'delivery_man', headerName: 'Delivery Man', width: 200 },
        { field: 'customer_code', headerName: 'Customer Code', width: 200 },
        { field: 'customer_name', headerName: 'Customer Name', width: 200 },
        { field: 'product_name', headerName: 'Product Name', width: 200 },
        { field: 'date', headerName: 'Date', width: 200 },
        { field: 'amount', headerName: 'Amount', width: 200 },
        { field: 'amount_paid', headerName: 'Paid Amount', width: 200 },
        { field: 'remaining_balance', headerName: 'Remaining Balance', width: 200 }
    ];
    console.log("reports array", reportsDataArray)
    useEffect(() => {
        if (!localStorage.getItem("userid")) {
            navigate("/login")
        }
    }, [])
    return (
        <div>
            <div>
                <Container fluid>
                    <div className='main my-3'>
                        <Row>

                            <Col lg="12" md="12" xs="12">
                                <div className="page-nav py-3 h5">
                                    Sales & Delivery Pending Data
                                </div>
                            </Col>
                            <Col lg='2' md='6' xs='12' className='py-3'>
                                <div className="w-100">
                                    <Calender
                                        setLabel={(date) => setLabel(date, "date")}
                                        selectedDate={formData.date}
                                        calenderlabel="Date"
                                    />
                                </div>
                            </Col>
                            <Col lg='2' md='6' xs='12' className='py-3'>
                                <div className='w-100'>
                                    <DropDownUI
                                        optionlist={product}
                                        placeholder="Product"
                                        labelname="Product"
                                        name="product"
                                        value={formData.product}
                                        onChange={(updatedFormData) =>
                                            setFormData({
                                                ...formData,
                                                product: updatedFormData.product,
                                            })
                                        }
                                    ></DropDownUI>
                                </div>
                            </Col>
                            <Col lg='2' md='6' xs='12' className='py-3'>
                                <div className='w-100'>
                                    <DropDownUI
                                        optionlist={roll}
                                        placeholder="Roll"
                                        labelname="Roll"
                                        name="roll"
                                        value={formData.roll}
                                        onChange={(updatedFormData) =>
                                            setFormData({
                                                ...formData,
                                                roll: updatedFormData.roll,
                                            })
                                        }
                                    ></DropDownUI>
                                </div>
                            </Col>
                            <Col lg='2' md='6' xs='12' className='py-3'>
                                <div className='w-100'>
                                    <DropDownUI
                                        optionlist={personId}
                                        placeholder="Person Id"
                                        labelname="Person Id"
                                        name="personid"
                                        value={formData.personid}
                                        onChange={(updatedFormData) =>
                                            setFormData({
                                                ...formData,
                                                personid: updatedFormData.personid,
                                            })
                                        }
                                    ></DropDownUI>
                                </div>
                            </Col>
                            <Col lg='2' md='6' xs='12' className='py-3'>
                                <div className='w-100'>
                                    <DropDownUI
                                        optionlist={paymentstatus}
                                        placeholder="Payment Method"
                                        labelname="Payment Method"
                                        name="payment_status"
                                        value={formData.payment_status}
                                        onChange={(updatedFormData) =>
                                            setFormData({
                                                ...formData,
                                                payment_status: updatedFormData.payment_status,
                                            })
                                        }
                                    ></DropDownUI>
                                </div>
                            </Col>
                            <Col lg="12" md="6" xs="12" className="align-self-center py-3">
                                <div className="d-flex mt-4 justify-content-end">
                                    <div className="px-2">
                                        <ClickButton
                                            onClick={reportsData}
                                            label={<>SUBMIT</>}
                                            className="create-btn "
                                        ></ClickButton>
                                    </div>
                                    <div className="px-2">
                                        <ClickButton
                                            onClick={downloadExcel}
                                            label={<>Download Excel</>}
                                            className="create-btn "
                                        ></ClickButton>
                                    </div>

                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div style={{ height: 500, width: '100%' }}>
                                    <DataGrid
                                        rows={reportsDataArray}
                                        columns={columns}
                                        loading={loading}
                                        autoPageSize
                                        getRowId={(row) => Math.random()}
                                        disableSelectionOnClick
                                        sx={{
                                            '& .MuiDataGrid-columnHeader': {
                                                backgroundColor: '#4B0082',
                                                color: 'white',
                                                fontWeight: 'bold',
                                                '&:hover': {
                                                    '.MuiSvgIcon-root': {
                                                        color: 'white', // Change the icon color to white on hover
                                                    },
                                                },
                                            },
                                            '& .MuiDataGrid-columnHeader--alignLeft': {
                                                textAlign: 'left',
                                            },
                                            '& .MuiDataGrid-columnHeader--sortable': {
                                                cursor: 'pointer',
                                            },
                                            '& .MuiDataGrid-withBorderColor': {
                                                borderColor: '#530004',
                                            },
                                            '& .MuiDataGrid-scrollbar--vertical': {
                                                display: 'none',
                                            },
                                        }}
                                    />


                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>

            </div >
        </div >
    )
}

export default Report
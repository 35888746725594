import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import { useNavigate, Navigate } from 'react-router-dom';
import { Buttons, ClickButton } from '../components/Buttons';
import { TableUI } from '../components/Tables'
import Pagnation from '../components/Pagnation';
import { TextInputForm, DropDownUI } from '../components/Forms';
import { PageHead, PageTitle } from '../components/PageTitle'
import { FaMagnifyingGlass } from "react-icons/fa6";
import { VscEyeClosed, VscEye } from 'react-icons/vsc';
import PropagateLoader from "react-spinners/PropagateLoader";
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UserTablehead = ["S.No", "Devision Name", "Vechile Name", "Receipt Number", "Balance Amount", "Action"]
const UserContent = [{
    "id": "1",
    "role": "VIRUTHUNAGAR",
    "type": "HONDA",
    "username": "RE/01/001",
    "password": "3000"
},


]

const SalesAgent = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { type, rowData } = location.state || {};
    const [show, setShow] = useState(false);

    // useState(() => {
    //     if (!localStorage.getItem('auth')) navigate("/login");
    // }, [navigate]);

    const handleClose = () => {
        setShow(false)
        location.state = [];
    };
    const handleShow = () => {
        setCrtUser(null)
        setFormData({
            name: '',
            phone_number: '',
            password: ''
        })
        setShow(true);
    }
    const [showPassword, setShowPassword] = useState(false);
    const [crtuser, setCrtUser] = useState(null);

    // Function to open modal
    const handleEditClick = (rowdata) => {
        setCrtUser(rowdata)
        setShow(true)

    };
    const [formData, setFormData] = useState({
        name: '',
        phone_number: '',
        password: ''
    });
    const [userData, setUserData] = useState([]);
    console.log(userData);
    const handleChange = (e, fieldName) => {
        const value = e.target ? e.target.value : e.value;
        if (crtuser != null) {
            setCrtUser({
                ...crtuser,
                [fieldName]: value
            });
        } else {
            setFormData({
                ...formData,
                [fieldName]: value
            });
        }
    };
    const [loading, setLoading] = useState(true);
    const [searchText, setSearchText] = useState('');
    const fetchData = async () => {
        try {
            const companyId = localStorage.getItem('companyId');
            console.log('https://billing.barbikan.in/user/list/' + companyId);
            const response = await fetch('https://billing.barbikan.in/user/list/' + companyId, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    search_text: ''
                })
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const responseData = await response.json();
            setLoading(false);
            if (responseData.status === 200) {
                setUserData(responseData.data);
                console.log(setUserData)
            } else {
                throw new Error(responseData.msg);
            }
        } catch (error) {
            setLoading(false);
            console.error('Error fetching data:', error.message);
        }
    };
    useEffect(() => {
        fetchData(); // Call fetchData directly in useEffect

    }, [searchText]);
    const [showAlert, setShowAlert] = useState(false);
    const errorAlert = (input) => {
        toast.error(input, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        });
    }
    const successAlert = (success) => {
        toast.success(success, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",

        });
    }
    const handleSubmit = async () => {
        try {
            if (formData.name === '' || formData.password === '' || formData.phone_number === '') {
                if (formData.name === '') {
                    errorAlert("Name is Must");
                } else if (formData.phone_number === '') {
                    errorAlert("Mobile Number is Must");
                } else if (formData.password === '') {
                    errorAlert("Password is Must")
                }

            } else {
                const companyId = localStorage.getItem('companyId');
                const response = await fetch('https://billing.barbikan.in/user/create/' + companyId, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(formData)
                });
                console.log(formData);
                const responseData = await response.json();

                console.log(responseData);

                if (responseData.status === 200) {
                    setFormData({
                        name: '',
                        phone_number: '',
                        password: ''
                    })
                    fetchData();
                    successAlert(responseData.msg)
                    setTimeout(() => {
                        handleClose(); // Close modal after 10 seconds
                    }, 2000);
                }
                else if (responseData.status === 400) {
                    toast.error('Missing required fields!', {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
                else {
                    setShowAlert(true);
                }
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };
    const handleUpdateSubmit = async () => {
        try {
            const companyId = localStorage.getItem('companyId');
            const response = await fetch('https://billing.barbikan.in/user/update/' + companyId, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ // Include the company ID in the request
                    user_id: crtuser.user_id,
                    name: crtuser.name,
                    phone_number: crtuser.phone_number,
                    password: crtuser.password,
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to update company');
            }

            const responseData = await response.json();
            console.log(responseData);

            if (responseData.status === 200) {
                fetchData();
                toast.success('User updated successfully!', {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setTimeout(() => {
                    handleClose();
                }, 2000);

                // Navigate to the user list page after a delay

            } else {
                console.error(responseData.msg || 'Unknown error occurred during update');
            }
        } catch (error) {
            console.error('Error updating product:', error.msg);
        }

        setLoading(false);
    };



    // const [isLoggedIn, setIsLoggedIn] = useState(true);
    // useEffect(() => {
    //     const checkSession = async () => {
    //         try {
    //             // Check session logic here, e.g., fetch from local storage or server
    //             const session = localStorage.getItem('session');
    //             if (!session) {
    //                 setIsLoggedIn(false);
    //             }
    //         } catch (error) {
    //             console.error('Error checking session:', error.message);
    //             setIsLoggedIn(false);
    //         }
    //     };
    //     checkSession();
    // }, []);
    // if (!isLoggedIn) {

    //     return <Navigate to="/login" replace />;
    // }
    return (
        <div>
            <div>
                <Container fluid>
                    <div className='main my-3'>
                        <Row>
                            <Col lg={6} md={6} xs={6}>
                                <h3 className='py-3 '>SALES MAN </h3>
                            </Col>
                            <Col lg={6} md={6} xs={6}>
                                <div className='text-end py-3'>
                                    <Button className='add-new' onClick={handleShow}>Add New</Button>
                                </div>
                            </Col>
                            <Col lg={12} md={12} xs={12} className='py-2'>
                                <div className='py-2 table-scroll'>
                                    <TableUI headers={UserTablehead} body={UserContent} type="USER" />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
                <>
                    <Modal show={show} onHide={handleClose} size='xl' centered scrollable>
                        <Modal.Header>
                            <Modal.Title>
                                <PageHead pagehead={`CollectionAgent${crtuser != null ? 'Update' : 'Creation'}`} onClick={handleClose} />
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Container fluid>
                                <Row>
                                    <Col lg='4' md='6' xs='12' className='py-3'>
                                        <TextInputForm
                                            labelname={'Devision Name'}
                                            placeholder={'Devision Name'}
                                            name='devision_name'
                                            value={crtuser != null ? crtuser.devision_name : formData.devision_name}
                                            onChange={(e) => handleChange(e, 'devision_name')}
                                        ></TextInputForm>
                                    </Col>
                                    <Col lg='4' md='6' xs='12' className='py-3'>
                                        <TextInputForm
                                            labelname={'Vechile Name'}
                                            placeholder={'Vechile Name'}
                                            name='vechile_name'
                                            value={crtuser != null ? crtuser.vechile_name : formData.vechile_name}
                                            onChange={(e) => handleChange(e, 'vechile_name')}
                                        ></TextInputForm>
                                    </Col>
                                    <Col lg='4' md='6' xs='12' className='py-3'>
                                        <TextInputForm
                                            labelname={'Vechile Number'}
                                            placeholder={'Vechile Number'}
                                            type={'vechile_number'}
                                            name='vechile_number'
                                            value={crtuser != null ? crtuser.vechile_number : formData.vechile_number}
                                            onChange={(e) => handleChange(e, 'vechile_number')}
                                        ></TextInputForm>
                                    </Col>
                                    <Col lg='4' md='6' xs='12' className='py-3'>
                                        <TextInputForm
                                            labelname={'Receipt Number'}
                                            placeholder={'Receipt Number'}
                                            type={'receipt_number'}
                                            name='receipt_number'
                                            value={crtuser != null ? crtuser.receipt_number : formData.receipt_number}
                                            onChange={(e) => handleChange(e, 'receipt_number')}
                                        ></TextInputForm>
                                    </Col>
                                </Row>
                            </Container>
                        </Modal.Body>
                        <Modal.Footer>
                            <Col lg='12' md='12' xs='12' className='py-5 align-self-center'>
                                <div className='text-center'>
                                    {crtuser != null ? (
                                        <>
                                            <ToastContainer
                                                position="top-center"
                                                autoClose={3000}
                                                hideProgressBar={false}
                                                newestOnTop={false}
                                                closeOnClick
                                                rtl={false}
                                                pauseOnFocusLoss
                                                draggable
                                                pauseOnHover
                                                theme="light"
                                            />
                                            <span className='mx-2'>
                                                <ClickButton label={<>Update</>} onClick={handleUpdateSubmit}></ClickButton>

                                            </span>

                                            <span className='mx-2'>
                                                <ClickButton label={<>Cancel</>} onClick={handleClose}></ClickButton>
                                            </span>
                                        </>

                                    ) : (
                                        <>
                                            <ToastContainer
                                                position="top-center"
                                                autoClose={3000}
                                                hideProgressBar={false}
                                                newestOnTop={false}
                                                closeOnClick
                                                rtl={false}
                                                pauseOnFocusLoss
                                                draggable
                                                pauseOnHover
                                                theme="light"
                                            />
                                            <span className='mx-2'>
                                                <ClickButton label={<>Submit</>} onClick={handleSubmit}></ClickButton>
                                            </span>

                                            <span className='mx-2'>
                                                <ClickButton label={<>Cancel</>} onClick={handleClose}></ClickButton>
                                            </span>
                                        </>
                                    )}
                                </div>
                            </Col>
                        </Modal.Footer>
                    </Modal>
                </>
            </div>
        </div>
    )
}

export default SalesAgent
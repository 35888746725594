import { BrowserRouter, Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import React, { useState, useEffect, useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import SideBar from './components/SideBar'
import Dashboard from './pages/Dashboard';
import './components/Table.css'
import Login from './pages/Login';
import User from './pages/User';
import CollectionAgent from './pages/CollectionAgent';
import Mis from './pages/Mis';

import ExcelUpload from './pages/ExcelUpload';
import SalesAgent from './pages/SalesAgent';
import MisUploadExcel from './pages/MisUploadExcel';
import MisManagerExcel from './pages/MisManagerExcel';
import Report from './pages/Report';
import MisSalesReEntry from './pages/MisSalesReEntry';
import { UserContext } from './context/userContext';
import Customer from './pages/Customer';
import DeliveryReEntry from './pages/DeliveryReEntry';
import CollectionPdf from './pages/CollectionPdf';
function App() {
  const { user, setStatus } = useContext(UserContext)
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const session = localStorage.getItem('session');
    if (session) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, []);

  const handleLogin = () => {
    localStorage.setItem('session', 'true');
    setLoggedIn(true);
    setStatus(curr => !curr)

  };

  const handleLogout = () => {
    localStorage.removeItem('session');
    setLoggedIn(false);
    return <Navigate to="/login" replace />;
  };
  // console.log(first)
  console.log(user)

  useEffect(() => {
    if (user === null) {
      setLoggedIn(false)
      setStatus(curr => !curr)
    }
    else {
      setLoggedIn(true)
    }
  }, [])
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={loggedIn ? <Navigate to="/dashboard" replace /> : <Navigate to="/login" replace />} />
          <Route path='/login' element={<Login onLogin={handleLogin} />}></Route>
          <Route element={<SideBar onLogout={handleLogout} />}>
            <Route path='/dashboard' element={<Dashboard />} ></Route>
            <Route path='/console/user' element={<User />}></Route>
            <Route path='/console/customer' element={<Customer />}></Route>
            <Route path='/console/collectionagent' element={<CollectionAgent />}></Route>
            <Route path='/console/delivery' element={<DeliveryReEntry />} />

            <Route path='/console/mis' element={<Mis />}></Route>
            <Route path='/console/mis/uploadexcel' element={<MisUploadExcel />} />
            <Route path='/console/mis/managerexcel' element={<MisManagerExcel />} />
            <Route path='/console/mis/salesreentry' element={<MisSalesReEntry />} />
            <Route path='/console/sales' element={<SalesAgent />} />
            <Route path='/console/uploadexcel' element={<ExcelUpload />} />
            <Route path='/console/report' element={<Report />} />
            <Route path='/console/pdf' element={<CollectionPdf/>} />
          </Route>

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

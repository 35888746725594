import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { ClickButton } from "../components/Buttons";
import { DropDownUI, Calender } from "../components/Forms";
import moment from "moment";
import axiosRequest from "../service/api";
import { Grid, Input, InputLabel, FormControl, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import { PDFDownloadLink, BlobProvider } from '@react-pdf/renderer';
import CollectionWorkPdf from "./CollectionWorkPdf";
const product = [
  { label: "PEPSI", value: "pepsi" },
  { label: "BRITANNIA", value: "britannia" },
  { label: "COLGATE", value: "colgate" },
];
const roll = [
  { label: "Delivery Man", value: "deliveryagent" },
  { label: "Salesman", value: "salesagent" },
];



const CollectionAgent = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    date: "",
    product: "",
    roll: "",
    personid: "",
    payment_method: "",
  });
  console.log("formData", formData);
  const [personId, setPersonId] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleChange = (e, fieldName) => {
    const value = e.target ? e.target.value : e.value;
    setFormData({ ...formData, [fieldName]: value });
  };

  const setLabel = (value, field) => {
    setFormData({
      ...formData,
      [field]: moment(value).format("YYYY-MM-DD"),
    });
  };

  const fetchUsersByRole = async () => {
    try {
      const role = `${formData.roll}`;
      const headers = {
        Authorization: `${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      };
      const response = await axiosRequest(
        "get",
        "user/filterbyrole",
        null,
        { role },
        { headers }
      );
      if (Array.isArray(response.users)) {
        const Login_id_array = response.users.map((user) => ({
          label: user.login_id,
          value: user.login_id,
        }));
        setPersonId(Login_id_array);
      } else {
        console.log("No users found with the specified role.");
      }
    } catch (error) {
      console.error("Error fetching users by role:", error);
    }
  };
  console.log("vijay FormData", formData);
  const [totalDelivery, setTotalDelivery] = useState([])
  console.log('totalDelivery', totalDelivery)
  const collectionAgentDataArray = async () => {

    setLoading(true);
    const { date, roll, personid, product } = formData;
    const headers = {
      Authorization: `${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    };

    try {
      const response = await axiosRequest(
        "get",
        "collectionagent/getdeliverymendetailsdate",
        null,
        {
          date,
          role: roll,
          loginId: personid,
          productname: product
        },
        { headers }
      );
      setTotalDelivery(response)

    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsersByRole();
  }, [formData.roll]);

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      personid: "",
    }));
  }, [formData.roll]);
  useEffect(() => {
    if (!localStorage.getItem("userid")) {
      navigate("/login");
    }
  }, []);
  return (
    <div>
      <Container fluid>
        <div className="main my-3">
          <Row>
            <Col lg="12" md="12" xs="12">
              <div className="page-nav py-3 h5">Collection Data</div>
            </Col>
            <Col lg="2" md="6" xs="12" className="py-3">
              <div className="w-100">
                <Calender
                  setLabel={(date) => setLabel(date, "date")}
                  selectedDate={formData.date}
                  calenderlabel="Date"
                />
              </div>
            </Col>
            <Col lg="2" md="6" xs="12" className="py-3">
              <div className="w-100">
                <DropDownUI
                  optionlist={product}
                  placeholder="Product"
                  labelname="Product"
                  name="product"
                  value={formData.product}
                  onChange={(updatedFormData) =>
                    setFormData({
                      ...formData,
                      product: updatedFormData.product,
                    })
                  }
                />
              </div>
            </Col>
            <Col lg="2" md="6" xs="12" className="py-3">
              <div className="w-100">
                <DropDownUI
                  optionlist={roll}
                  placeholder="Roll"
                  labelname="Roll"
                  name="roll"
                  value={formData.roll}
                  onChange={(updatedFormData) =>
                    setFormData({ ...formData, roll: updatedFormData.roll })
                  }
                />
              </div>
            </Col>
            <Col lg="2" md="6" xs="12" className="py-3">
              <div className="w-100">
                <DropDownUI
                  optionlist={personId}
                  placeholder="Person Id"
                  labelname="Person Id"
                  name="personid"
                  value={formData.personid}
                  onChange={(updatedFormData) =>
                    setFormData({
                      ...formData,
                      personid: updatedFormData.personid,
                    })
                  }
                />
              </div>
            </Col>
            <Col lg="2" md="6" xs="12" className="align-self-center py-3">
              <div className="d-flex mt-4 justify-content-end">
                <div className="px-2">
                  <ClickButton
                    onClick={collectionAgentDataArray}
                    label={<>SUBMIT</>}
                    className="create-btn"
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            {/* Other form fields */}
            <Col lg="12" md="12" xs="12" className="mt-5">
              {totalDelivery.collectionAgentSubmisson === true ? (
                <>
                  {/* Download the PDF */}
                  <PDFDownloadLink className="mx-5"
                    document={<CollectionWorkPdf totalDelivery={totalDelivery} />}
                    fileName="Delivery_Report.pdf"
                  >
                    {({ loading }) =>
                      loading ? (
                        <ClickButton label="Loading PDF..." className="success-btn" />
                      ) : (
                        <ClickButton label="Download PDF" className="success-btn" />
                      )
                    }
                  </PDFDownloadLink>

                  {/* Inline preview (optional) */}
                  <BlobProvider document={<CollectionWorkPdf totalDelivery={totalDelivery} />}>
                    {({ url }) => (
                      <a href={url} target="_blank" rel="noopener noreferrer">
                        <ClickButton label="View PDF" className="info-btn" />
                      </a>
                    )}
                  </BlobProvider>
                </>
              ) : totalDelivery.collectionAgentSubmisson === false ? (
                <div>
                  <p>No submission available at the moment.</p>
                  <h3>{totalDelivery.msg}</h3>
                </div>
              ) : null}
            </Col>
          </Row>
        </div>
      </Container>
      <div className="text-center daily-generation-section">
        <div className="fixed-button-bar">
          <div className="d-flex">
            <div>
              <p style={{ fontSize: "12px" }}>
                Copyright @ 2024 , VsAgency Developed By Barbikan
                Technologies.All rights reserved
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollectionAgent;

import React, { useState,useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { Button, Navbar, Spinner } from 'react-bootstrap';
import {  DropDownUI } from '../components/Forms';
import { MdLogout} from "react-icons/md";
import './Header.css';
import { FaBars, FaSignOutAlt } from 'react-icons/fa';
const Header = () => {

  const [isLoading, setIsLoading] = useState(false);
  const initialCompanyId = localStorage.getItem('companyId') || '';

const [formData,setFormData]=useState({
  company_id:initialCompanyId
})
  const [userData, setUserData] = useState([]);
    const fetchData = async () => {
        try {
            const response = await fetch('https://billing.barbikan.in/company/list/joyg67j8t689t', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    search_text: ''
                })
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            const responseData = await response.json();
          
            if (responseData.status === 200) {
                setUserData(responseData.data);
                console.log(responseData)
            } else {
                throw new Error(responseData.msg);
            }
        } catch (error) {
          
            console.error('Error fetching data:', error.message);
        }
    };
    useEffect(() => {
        fetchData(); // Call fetchData directly in useEffect
    }, []);
  const handleToggle = () => {
    document.body.classList.toggle('toggle-sidebar');
  };
  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userid')
    window.location.replace("/login"); // Redirect to login page after logout
  };
  const handleCompanyChange = (updatedFormData) => {
    setFormData({ ...formData, company_id: updatedFormData.company_id });
    localStorage.setItem('companyId', updatedFormData.company_id); // Store selected company in localStorage
    window.location.reload();
};
  return (
    <div>
      <Navbar className='navfix navbg header fixed-top'>
        <Container fluid>
          <div className='align-self-center'>
            <Button className="menu-toggle" id="menu-toggle" onClick={handleToggle}>
              <span className="navbar-toggler-icon"></span>
            </Button>
          </div>
          <div className='d-flex'>
            <div className='user-logo mx-2'>
              <img src={require('../assests/images/vs-agencyy.png')} className='img-fluid logo' alt="" />
            </div>
            <div className='user-logovs mx-2'>
              <img src={require('../assests/images/vslogo.png')} className='img-fluid' alt="" />
            </div>
          </div>
        
          <div className='ms-auto d-flex align-items-center'>
            <div className='mx-5 switch-company'>
            
            </div>
            
            <div className='ms-auto user-id'>
              <Button>
                <FaSignOutAlt onClick={handleLogout} className='mdlogout' style={{fontSize:"28px"}}/><span className='mx-2' style={{fontSize:"18px"}}>Logout</span>
              </Button>
            </div>
          </div>
        </Container>
      </Navbar>
      {isLoading && (
        <div className="loading-overlay">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
    </div>
  );
};

export { Header };

// const Header = () => {
//   const [currentCompany, setCurrentCompany] = useState('Rathinam Traders');
//   const [isLoading, setIsLoading] = useState(false);
//   const companies = ['Rathinam Traders', 'MuthuRathinam Traders'];
  
//   const handleCompanySwitch = (company) => {
//     setIsLoading(true);
//     setTimeout(() => {
//       setCurrentCompany(company);
//       localStorage.setItem("company_id", company) 
//       setIsLoading(false); 
//     }, 2000); 
//   };
//   const handleToggle = () => {
//     document.body.classList.toggle('toggle-sidebar');
//   };
//   return (
//     <div>
//       <Navbar className='navfix navbg header fixed-top'>
//         <Container fluid>
//           <div className='align-self-center'>
//             <Button className="menu-toggle" id="menu-toggle" onClick={handleToggle}>
//               <span className="navbar-toggler-icon"></span>
//             </Button>
//           </div>
//           <div className='user-logo mx-5'>
//             <img src={require('../assests/images/logo.png')} className='img-fluid logo' alt="" />
//           </div>
//           <div className='ms-auto d-flex align-items-center'>
            // <div className='mx-5 switch-company'>
            //   <Dropdown>
            //     <Dropdown.Toggle variant="secondary" id="dropdown-basic">
            //       {currentCompany}
            //     </Dropdown.Toggle>
            //     <Dropdown.Menu>
            //       {companies.map((company, index) => (
            //         <Dropdown.Item key={index} onClick={() => handleCompanySwitch(company)}>
            //           {company}
            //         </Dropdown.Item>
            //       ))}
            //     </Dropdown.Menu>
            //   </Dropdown>
            // </div>
//             <div className='ms-auto user-id'>
//               <Button>
//                 <MdLogout />
//               </Button>
//             </div>
//           </div>
//         </Container>
//       </Navbar>
//       {isLoading && (
//         <div className="loading-overlay">
//           <Spinner animation="border" variant="primary" />
//         </div>
//       )}
//     </div>
//   );
// };

// export { Header };

import React, { useState, useEffect, useContext } from 'react';
import './SideBar.css';
import { NavLink, Route, Routes } from 'react-router-dom';
import { MdOutlineKeyboardArrowRight } from 'react-icons/md';
import {
  MdOutlineHome,
  MdOutlinePerson,
  MdBusiness,
  MdOutlineAddShoppingCart,
  MdOutlineCategory,
  MdOutlineContentPasteGo,
  MdOutlineAddCard,
  MdOutlineFeed,
  MdMenuBook
} from 'react-icons/md';
import { PiUserFocus } from "react-icons/pi";
import { FaCloudUploadAlt } from "react-icons/fa";
import { FaBusAlt } from "react-icons/fa";
import { RiMoneyRupeeCircleLine } from "react-icons/ri";
import { PiUsers } from 'react-icons/pi';
import { LuDot } from 'react-icons/lu';
import { Collapse } from 'react-bootstrap';
import { Header } from './Header';
import Dashboard from '../pages/Dashboard';
import User from '../pages/User';
import CollectionAgent from '../pages/CollectionAgent';
import Mis from '../pages/Mis'

import ExcelUpload from '../pages/ExcelUpload';
import SalesAgent from '../pages/SalesAgent';
import MisUploadExcel from '../pages/MisUploadExcel';
import MisManagerExcel from '../pages/MisManagerExcel';

import Report from '../pages/Report';
import MisSalesReEntry from '../pages/MisSalesReEntry';
import { UserContext } from '../context/userContext';
import Customer from '../pages/Customer';
import DeliveryReEntry from '../pages/DeliveryReEntry';
import CollectionPdf from '../pages/CollectionPdf';
const SideBar = () => {
  const { user } = useContext(UserContext)
  // Initialize openMenu state from local storage or set as empty object if none found
  const [openMenu, setOpenMenu] = useState(JSON.parse(localStorage.getItem('openMenu')) || {});

  // Function to handle submenu clicks
  const handleMenuClick = (menuIndex) => {
    // Set state to keep only the clicked submenu open and close others
    setOpenMenu((prevOpenMenu) => {
      const newOpenMenu = {};
      // Close all submenus
      for (let key in prevOpenMenu) {
        newOpenMenu[key] = false;
      }
      // Open the clicked submenu
      newOpenMenu[menuIndex] = !prevOpenMenu[menuIndex];
      return newOpenMenu;
    });
  };

  // Save openMenu state to local storage whenever it changes
  useEffect(() => {
    localStorage.setItem('openMenu', JSON.stringify(openMenu));
  }, [openMenu]);

  return (
    <div className=''>
      <Header />
      <aside id='side-bar' className='side-bar'>
        <div className='list-group'>
          <ul>
            <li>
              <NavLink to='/dashboard' className={`nav-link ${user && ["admin"].includes(user.role) ? "" : 'disabled'}`}>
                <span className='list-icon'><MdOutlineHome /></span>
                <span class='list-text'>Home</span>
              </NavLink>
            </li>
            <li>
              <NavLink to='/console/user' className={`nav-link ${user && ["admin"].includes(user.role) ? "" : 'disabled'}`} >
                <span className='list-icon'><MdOutlinePerson /></span>
                <span class='list-text'>User & Access</span>
              </NavLink>
            </li>
            {/* <li>
              <NavLink to='/console/customer' className={`nav-link ${user && ["admin"].includes(user.role) ? "" : 'disabled'}`}>
                <span className='list-icon'><PiUserFocus /></span>
                <span class='list-text'>Customer</span>
              </NavLink>
            </li> */}
            <li>
              <NavLink to='/console/collectionagent' className={`nav-link ${user && ["admin","collectionagent"].includes(user.role) ? "" : 'disabled'}`}>
                <span className='list-icon'><RiMoneyRupeeCircleLine /></span>
                <span class='list-text'>Collection Agent</span>
              </NavLink>
            </li>
            <li>
              <NavLink to='/console/delivery' className={`nav-link ${user && ["admin","pepsimanager", "britaniamanager", "colgatemanager"].includes(user.role) ? "" : 'disabled'}`}>
                <span className='list-icon'><FaBusAlt /></span>
                <span class='list-text'>Delivery ReEntry</span>
              </NavLink>
            </li>
            {/* <li>
              <NavLink to='/console/mis' className='nav-link'>
                <span className='list-icon'><MdOutlineAddCard /></span>
                <span class='list-text'>MIS</span>
              </NavLink>
            </li> */}
            {/* <li>
              <NavLink to='/console/sales' className='nav-link'>
                <span className='list-icon'><MdOutlineContentPasteGo /></span>
                <span class='list-text'>Sales Man</span>
              </NavLink>
            </li> */}
            {/* Parties submenu */}
            <li>
              <div className='sub-menu nav-link' onClick={() => handleMenuClick(0)}>
                <span className='list-icon'><PiUsers /></span>
                <span class='list-text'>Manager</span>
                <span className={`list-icon arrow ${openMenu[0] ? 'rotate' : ''}`}><MdOutlineKeyboardArrowRight /></span>
              </div>
              <Collapse in={openMenu[0]}>
                <ul className='submenu-list'>
                  <li>
                    <NavLink to='/console/uploadexcel' className={`nav-link ${user && ["pepsimanager", "britaniamanager", "colgatemanager", "admin"].includes(user.role) ? '' : 'disabled'}`}>
                      <span className='list-icon'><FaCloudUploadAlt /></span>
                      <span class='list-text'>ExcelUpload</span>
                    </NavLink>
                  </li>
                </ul>
              </Collapse>
            </li>
            {/* Items submenu */}
            <li>
              <div className='sub-menu nav-link' onClick={() => handleMenuClick(1)}>
                <span className='list-icon'><MdOutlineCategory /></span>
                <span class='list-text'>MIS</span>
                <span className={`list-icon arrow ${openMenu[1] ? 'rotate' : ''}`}><MdOutlineKeyboardArrowRight /></span>
              </div>
              <Collapse in={openMenu[1]}>
                <ul className='submenu-list'>
                  <li>
                    <NavLink to='/console/mis/uploadexcel' className={`nav-link ${user && ["admin", "mis"].includes(user.role) ? "" : 'disabled'}`}>
                      <span className='list-icon'><LuDot /></span>
                      <span class='list-text'>ExcelUpload</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to='/console/mis/managerexcel' className={`nav-link ${user && ["admin", "mis"].includes(user.role) ? "" : 'disabled'}`}>
                      <span className='list-icon'><LuDot /></span>
                      <span class='list-text'>Manager Excel Data</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to='/console/mis/salesreentry' className={`nav-link ${user && ["admin", "mis"].includes(user.role) ? "" : 'disabled'}`}>
                      <span className='list-icon'><LuDot /></span>
                      <span class='list-text'>SalesManReEntry</span>
                    </NavLink>
                  </li>
                </ul>
              </Collapse>
            </li>
            <li>
              <NavLink to='/console/report' className={`nav-link ${user && ["admin","mis"].includes(user.role) ? "" : 'disabled'}`}>
                <span className='list-icon'><MdOutlinePerson /></span>
                <span class='list-text'>Report</span>
              </NavLink>
            </li>
            {/* Purchase submenu */}
            {/* <li>
              <div className='sub-menu nav-link' onClick={() => handleMenuClick(2)}>
                <span className='list-icon'><MdOutlineAddShoppingCart /></span>
                <span class='list-text'>Purchase</span>
                <span className={`list-icon arrow ${openMenu[2] ? 'rotate' : ''}`}><MdOutlineKeyboardArrowRight /></span>
              </div>
              <Collapse in={openMenu[2]}>
                <ul className='submenu-list'>
                  <li>
                    <NavLink to='/console/purchase/bill' className='nav-link'>
                      <span className='list-icon'><LuDot /></span>
                      <span class='list-text'>Bill</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to='/console/purchase/payout' className='nav-link'>
                      <span className='list-icon'><LuDot /></span>
                      <span class='list-text'>Pay Out</span>
                    </NavLink>
                  </li>
                </ul>
              </Collapse>
            </li> */}
            {/* Sales submenu */}
            {/* <li>
              <div className='sub-menu nav-link' onClick={() => handleMenuClick(3)}>
                <span className='list-icon'><MdOutlineContentPasteGo /></span>
                <span class='list-text'>Sales</span>
                <span className={`list-icon arrow ${openMenu[3] ? 'rotate' : ''}`}><MdOutlineKeyboardArrowRight /></span>
              </div>
              <Collapse in={openMenu[3]}>
                <ul className='submenu-list'>
                  <li>
                    <NavLink to='/console/sales/invoice' className='nav-link'>
                      <span className='list-icon'><LuDot /></span>
                      <span class='list-text'>Invoice</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to='/console/sales/payin' className='nav-link'>
                      <span className='list-icon'><LuDot /></span>
                      <span class='list-text'>Pay In</span>
                    </NavLink>
                  </li>
                </ul>
              </Collapse>
            </li> */}
            {/* Expense, DayBook, and Report links */}
            {/* <li>
              <NavLink to='/console/expense' className='nav-link'>
                <span className='list-icon'><MdOutlineAddCard /></span>
                <span class='list-text'>Expense</span>
              </NavLink>
            </li> */}

            {/* <li>
              <NavLink to='/console/report' className='nav-link'>
                <span className='list-icon'><MdMenuBook /></span>
                <span class='list-text'>Report</span>
              </NavLink>
            </li> */}
          </ul>
        </div>
      </aside>
      <div id='main'>
        <Routes>
          {/* Define routes for different pages */}
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/console/user' element={<User />} />
          <Route path='/console/customer' element={<Customer />} />
          <Route path='/console/collectionagent' element={<CollectionAgent />} />
          <Route path='/console/delivery' element={<DeliveryReEntry />} />
          <Route path='/console/mis' element={<Mis />} />
          <Route path='/console/mis/uploadexcel' element={<MisUploadExcel />} />
          <Route path='/console/mis/managerexcel' element={<MisManagerExcel />} />
          <Route path='/console/mis/salesreentry' element={<MisSalesReEntry />} />
          <Route path='/console/delivery' element={<DeliveryReEntry />} />
          <Route path='/console/sales' element={<SalesAgent />} />
          <Route path='/console/uploadexcel' element={<ExcelUpload />} />
          <Route path='/console/report' element={<Report />} />
          <Route path='/console/pdf' element={<CollectionPdf/>} />
        </Routes>
      </div>
    </div>
  );
};

export default SideBar;

import React, { useEffect,useState } from "react";
import { Card, Button, Container, Row, Col } from "react-bootstrap";
import { FaCalendarDay, FaCalendarAlt, FaRegCalendarCheck } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { FaRegCalendarMinus } from "react-icons/fa";
import axios from "axios";
const Dashboard = () => {
  const navigate = useNavigate();
  const currentDate = new Date();
  const day = currentDate.toLocaleString("en-US", { day: "2-digit" });
  const month = currentDate.toLocaleString("en-US", { month: "long" });
  const year = currentDate.getFullYear();
  const [dash, newDash] = useState([]); // State for storing API data
  console.log("dash",dash);
  
  useEffect(() => {
    axios.get('https://apivsagency.barbikan.in/dashboard/webdashboard')
      .then(response => {
        newDash(response.data);  // Update the state with fetched data
        console.log(response.data);  // Log the data to console
      })
      .catch(error => {
        console.error('Error fetching the API data:', error);
      });
  }, []);
  useEffect(() => {
    if (!localStorage.getItem("userid")) {
      navigate("/login")
    }
  }, [])
  return (
    <Container fluid>
      <div className='main my-3 ' style={{ height: "89vh" }}>
        <div>
          <Container fluid className="my-5 date-container">
            <Row className="justify-content-center text-center">
              <Col xs={12} md={6} lg={3} className="mb-4">
                <Card className="date-card day">
                  <Card.Body>
                    <FaCalendarDay className="icon" />
                    <Card.Title>Delivery Man Collection Amount</Card.Title>
                    <Card.Text>{dash.deliverymanCollectedAmount}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={6} lg={3} className="mb-4">
                <Card className="date-card month">
                  <Card.Body>
                    <FaCalendarAlt className="icon" />
                    <Card.Title>Sales Man Collection Amount</Card.Title>
                    <Card.Text>{dash.salesmanCollectedAmount}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} sm={6} md={3} className="mb-4">
                <Card className="date-card year">
                  <Card.Body>
                    <FaRegCalendarCheck className="icon" />
                    <Card.Title>Delivery Man (Customer) No Pay</Card.Title>
                    <Card.Text>{dash.pendingPaymentCountdeliveryman}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} sm={6} md={3} className="mb-4">
                <Card className="date-card sales">
                  <Card.Body>
                    <FaRegCalendarMinus className="icon" />
                    <Card.Title>Sales Man (Customer) No Pay</Card.Title>
                    <Card.Text>{dash.pendingPaymentCountSalesman}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </Container >
  )
}

export default Dashboard
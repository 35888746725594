import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import moment from 'moment';

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#FFFFFF',
        padding: 12,
        height: '100%',
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: 40,
    },
    header: {
        fontSize: 12,
        fontWeight: 'bold',
        marginBottom: 8,
    },
    flexRow: {
        flexDirection: 'row',
        marginBottom: 8,
        alignItems: 'center',
    },
    flexText: {
        fontSize: 13,
        fontWeight: 'bold',
        marginRight: 5,
    },
    flexTexts: {
        fontSize: 12,
        marginTop: 2,
        marginRight: 5,
    },
    flexTextss: {
        fontSize: 12,
        marginRight: 5,
    },
    salestop: {
        marginTop: 25,
    },
    salestopDenomination:{
        marginTop: 15,
    },
    denominationRow: {
        flexDirection: 'row',
        width: '22%',
        marginBottom: 4,
        alignItems: 'center',
        borderWidth: 1,
        borderColor: '#000',
        padding: 4,
        borderRadius: 5,
    },
    denominationTextvalue: {
        width: '25%',
        fontSize: 12,
    },
    denominationTextcount: {
        width: '25%',
        fontSize: 12,
    },
    denominationTextanswer: {
        width: '10%',
        fontSize: 12,
    },
    denominationTextanswers: {
        width: '10%',
        fontSize: 12,
    },
    denominationInput: {
        width: '20%',
        fontSize: 5,
        textAlign: 'center',
    },
    table: {
        display: 'table',
        width: 'auto',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#bfbfbf',
        marginBottom: 20, // To prevent overlap between sections
    },
    tableRow: {
        flexDirection: 'row',
    },
    tableCol: {
        width: '20%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#bfbfbf',
    },
    tableColsno: {
        width: '5%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#bfbfbf',
    },
    tableColcustomer: {
        width: '35%',
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: '#bfbfbf',
    },
    tableCell: {
        margin: 5,
        fontSize: 9,
    },
    footer:{
        marginTop:2,
    }
});

const CollectionWorkPdf = ({ totalDelivery }) => {
    const deliveries = totalDelivery?.delivery || [];
    const uniqueBeatCodes = Array.from(new Set(deliveries.map(delivery => delivery.beat_code_name)));
    const denominations = [500, 200, 100, 50, 20, 10, 5, 2, 1];
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.head}>
                    <Text style={styles.title}>Delivery Report</Text>
                </View>
                <View>
                    <Text style={styles.header}>VS AGENCY</Text>
                    <Text style={styles.header}>Address: 11/57, Meesalur, Virudhunagar, 626003</Text>
                    <Text style={styles.header}>Phone: 9443-68883</Text>
                    <Text style={styles.header}>GSTIN: 33AAQFY9862P1ZZ</Text>
                    <Text style={styles.header}>State: Tamil Nadu</Text>
                </View>
                <View style={styles.salestop}>
                    <View style={styles.flexRow}>
                        <Text style={styles.flexText}>SalesMan:</Text>
                        <Text style={styles.flexTexts}>{deliveries[0]?.delivery_man || 'N/A'}</Text>
                    </View>
                    <View style={styles.flexRow}>
                        <Text style={styles.flexText}>Beat Code Name:</Text>
                        <Text style={styles.flexTexts}>{uniqueBeatCodes.join(', ')}</Text>
                    </View>
                    <View style={styles.flexRow}>
                        <Text style={styles.flexText}>Date:</Text>
                        <Text style={styles.flexTexts}>{moment(deliveries[0]?.date).format('DD-MM-YYYY')}</Text>
                    </View>
                    <View style={styles.flexRow}>
                        <Text style={styles.flexText}>Product:</Text>
                        <Text style={styles.flexTextss}>{deliveries[0]?.product_name || 'N/A'}</Text>
                    </View>
                </View>
                {/* Table Section */}
                <View style={styles.table} wrap={false}> {/* Wrap false to avoid breaking table rows */}
                    {/* Table Header */}
                    <View style={styles.tableRow}>
                        <View style={styles.tableColsno}><Text style={styles.tableCell}>S.No</Text></View>
                        <View style={styles.tableColcustomer}><Text style={styles.tableCell}>Customer</Text></View>
                        <View style={styles.tableCol}><Text style={styles.tableCell}>Invoice</Text></View>
                        <View style={styles.tableCol}><Text style={styles.tableCell}>Amount</Text></View>
                        <View style={styles.tableCol}><Text style={styles.tableCell}>Payment Method</Text></View>
                    </View>
                    {/* Table Data - Dynamic Rows */}
                    {deliveries.length > 0 ? (
                        deliveries.map((delivery, index) => (
                            <View style={styles.tableRow} key={index} wrap={false}>
                                <View style={styles.tableColsno}><Text style={styles.tableCell}>{index + 1}</Text></View>
                                <View style={styles.tableColcustomer}><Text style={styles.tableCell}>{delivery.customer_name}</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{delivery.invoice_number}</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{delivery.amount}</Text></View>
                                <View style={styles.tableCol}><Text style={styles.tableCell}>{delivery.payment_method}</Text></View>
                            </View>
                        ))
                    ) : (
                        <View style={styles.tableRow}>
                            <Text style={styles.tableCell}>No deliveries available</Text>
                        </View>
                    )}
                </View>
                {/* Add a page break for the next section */}
            
                    <View style={styles.salestopDenomination}>
                        <Text style={styles.header}>Denomination Section</Text>
                    </View>
                    {/* Denomination Section */}
                    <View  wrap={false}>
                        {denominations.map((value, index) => (
                            <View style={styles.denominationRow} key={index}>
                                <Text style={styles.denominationTextvalue}>{value}</Text>
                                <Text style={styles.denominationTextcount}> × {totalDelivery.case_in_summery[value] || 0}</Text>
                                <Text style={styles.denominationTextanswer}>= </Text>
                                <Text style={styles.denominationTextanswers}>{value * (totalDelivery.case_in_summery[value] || 0)}</Text>
                            </View>
                        ))}
                    </View>
                    {/* Summary Section */}
                    <View style={styles.footer}>
                        <View style={styles.flexRow}>
                            <Text style={styles.flexText}>Total Cash Amount :</Text>
                            <Text style={styles.flexTexts}>{totalDelivery.totalCaseInAmount}</Text>
                        </View>
                        <View style={styles.flexRow}>
                            <Text style={styles.flexText}>Total Gpay Amount :</Text>
                            <Text style={styles.flexTexts}>{totalDelivery.totalGpayAmount}</Text>
                        </View>
                        <View style={styles.flexRow}>
                            <Text style={styles.flexText}>Total Check Amount :</Text>
                            <Text style={styles.flexTexts}>{totalDelivery.TotalCheckAmount}</Text>
                        </View>
                        <View style={styles.flexRow}>
                            <Text style={styles.flexText}>Total Credit Count :</Text>
                            <Text style={styles.flexTexts}>{totalDelivery.creditCount}</Text>
                        </View>
                    </View>
                    
            </Page>
        </Document>
    );
};

export default CollectionWorkPdf;

import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

// Create a Context object
const UserContext = createContext();

// Provide the Context to your component tree
const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [status, setStatus] = useState(false);

  // Fetch user data from API
  async function getUser() {
    try {
      if (localStorage.getItem("userid")) {
        const response = await axios.get(`https://apivsagency.barbikan.in/user/${localStorage.getItem("userid")}/getuserbyid`, {
          headers: {
            'Authorization': `${localStorage.getItem("token")}`,
            'Content-Type': "application/json"
          },
        });
        console.log(response)
        if (response.data.status === 200) {
          setUser(response.data.user)
        }
        else {
          setUser(null)
        }
        //   setUser(response.data.user);  // Set the user state with the fetched data
      }

    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  }

  // Use useEffect to call getUser only once when the component mounts
  useEffect(() => {
    getUser();
  }, [status]); // Empty dependency array ensures this runs only once after the initial render

  return (
    <UserContext.Provider value={{ user, setStatus }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserProvider, UserContext };


import React, { useEffect } from 'react'
import { Container, Col, Row, } from 'react-bootstrap';
import { NavLink, Route, Routes, useNavigate } from 'react-router-dom';
import { MdOutlineAccountBalance } from "react-icons/md";
import { MdOutlinePendingActions } from "react-icons/md";
import { RiPassPendingLine } from "react-icons/ri";
import { PiStudentBold } from "react-icons/pi";
import { FaPerson } from "react-icons/fa6";
import { SiCoursera } from "react-icons/si";
import pepsi from '../assests/images/pepsi.png'
import colgate from '../assests/images/colgate.png'
import britania from '../assests/images/britania.png'
const Mis = () => {
    const navigate=useNavigate();
    useEffect(()=>{
        if(!localStorage.getItem("userid")){
          navigate("/login")
        }
      },[])
    return (
        <>
            <Container fluid>
                <div className='main my-3 ' style={{ height: "115vh" }}>
                    <div>
                        <Row>
                            <Col lg={4} md={6} xs={12} className='py-3'>
                                <div className=''>

                                    <NavLink to='/console/mis/uploadexcel' className='nav-link'>
                                        <div className='boxsize p-4'>
                                            <span >< SiCoursera className='userEditMis icon-edit-Mis ' /></span>
                                            <span className='StudentList'>MIS Upload Excel</span>
                                        </div>
                                    </NavLink>

                                </div>
                            </Col>
                            <Col lg={4} md={6} xs={12} className='py-3'>
                                <div className=''>

                                    <NavLink to='/console/mis/managerexcel' className='nav-link'>
                                        <div className='boxsize p-4'>
                                            <span > <MdOutlineAccountBalance className='userEditMis icon-edit-Mis' /></span>
                                            <span className='StudentList'>Manager Excel Data</span>
                                        </div>
                                    </NavLink>

                                </div>
                            </Col>
                            <Col lg={4} md={6} xs={12} className='py-3'>
                                <div className=''>

                                    <NavLink to='/console/mis/deliverymanpending' className='nav-link'>
                                        <div className='boxsize p-4'>
                                            <span > <MdOutlinePendingActions className='userEditMis icon-edit-Mis' /></span>
                                            <span className='StudentList'>Delivery Man Pending Data</span>
                                        </div>
                                    </NavLink>

                                </div>
                            </Col>
                            <Col lg={4} md={6} xs={12} className='py-3'>
                                <div className=''>

                                    <NavLink to='/console/mis/salesmanpending' className='nav-link'>
                                        <div className='boxsize p-4'>
                                            <span > <RiPassPendingLine className='userEditMis icon-edit-Mis' /></span>
                                            <span className='StudentList'>Sales Man Pending Data</span>
                                        </div>
                                    </NavLink>

                                </div>
                            </Col>
                            <Col lg={4} md={6} xs={12} className='py-3'>
                                <div className=''>

                                    <NavLink to='/console/mis/salesmancolgate' className='nav-link' >
                                        <div className='boxsize p-4'>
                                            {/* <span >< PiStudentBold className='userEdit icon-edit' /></span> */}
                                            <span >< img src={colgate} className='userEditMis icon-edit-Mis w-25 img-icons' /></span>
                                            <span className='StudentList'>SalesMan Colgate</span>
                                        </div>
                                    </NavLink>

                                </div>
                            </Col>
                            <Col lg={4} md={6} xs={12} className='py-3'>
                                <div className=''>

                                    <NavLink to='/console/mis/salesmanpepsi' className='nav-link' >
                                        <div className='boxsize p-4'>
                                            {/* <span >< PiStudentBold className='userEdit icon-edit' /></span> */}
                                            <span >< img src={pepsi} className='userEditMis icon-edit-Mis w-25 img-icons' /></span>
                                            <span className='StudentList'>SalesMan Pepsi</span>
                                        </div>
                                    </NavLink>

                                </div>
                            </Col>

                            <Col lg={4} md={6} xs={12} className='py-3'>
                                <div className=''>

                                    <NavLink to='/console/mis/salesmanbritania' className='nav-link' >
                                        <div className='boxsize p-4'>
                                            {/* <span >< PiStudentBold className='userEdit icon-edit' /></span> */}
                                            <span >< img src={britania} className='userEditMis icon-edit-Mis w-25 img-icons' /></span>
                                            <span className='StudentList'>SalesMan Britania</span>
                                        </div>
                                    </NavLink>
                                </div>
                            </Col>
                        </Row>
                    </div >
                </div >

            </Container >
        </>
    )
}

export default Mis
import React, { useState, useEffect, useRef } from "react";
import { Container, Col, Row, Spinner, Modal, Button } from "react-bootstrap";
import { DataGrid } from "@mui/x-data-grid";
import {
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import {
  fetchAllCustomer,
  createCustomer,
  updateCustomer,
  deleteCustomer,
} from "./Api";
import { FaUserEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx"; // Import the XLSX library
import axios from "axios";

const Customer = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState([]);
  console.log("customer", userData);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [formData, setFormData] = useState({
    customer_name: "",
    customer_man_id: "",
    route: "",
    location: "",
    customer_phone_number: "",
  });
  console.log("formData", formData);
  const [jsonData, setJsonData] = useState([]);
  console.log("jsonData", jsonData);
  const [uploadedFile, setUploadedFile] = useState(null); // State to store the uploaded file
  const [showSubmitButton, setShowSubmitButton] = useState(false); // To control submit button visibility
  const fileInputRef = useRef(null);
  const fetchData = async () => {
    setLoading(true);
    try {
      const data = await fetchAllCustomer();
      setUserData(data); // Set data directly
    } catch (error) {
      console.error("Error fetching users:", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    setUploadedFile(file); // Set the file in the state
  };

  const handleSubmitFile = () => {
    if (!uploadedFile) {
      Swal.fire({
        icon: "error",
        title: "No File Selected",
        text: "Please select a file before submitting.",
        confirmButtonText: "OK",
      });
      return;
    }
  
    const formData = new FormData();
    formData.append("file", uploadedFile);
  
    axios
      .post("https://apivsagency.barbikan.in/customer/uploadexcel", formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Set automatically by Axios
          Authorization: `${localStorage.getItem("token")}`, // Add token if needed
        },
      })
      .then((response) => {
        console.log("File uploaded successfully", response.data);
  
        // Show success alert after the file is successfully uploaded
        Swal.fire({
          icon: "success",
          title: "File Submitted",
          text: "Your Customer file has been uploaded successfully!",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "my-ok-button-class",
          },
        });
  
        // Clear the uploaded file state
        setUploadedFile(null);
      })
      .catch((error) => {
        console.error("Error uploading file", error);
  
        // Show error alert if file upload fails
        Swal.fire({
          icon: "error",
          title: "File Upload Failed",
          text: `An error occurred: ${error.message}`,
          confirmButtonText: "OK",
        });
      });
  };
  const handleSubmit = async () => {
    if (
      !formData.customer_man_id ||
      !formData.customer_name ||
      !formData.location ||
      !formData.route ||
      !formData.customer_phone_number
    ) {
      Swal.fire({
        icon: "warning",
        title: "Missing fields",
        text: "All fields are required.",
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "my-ok-button-class",
        },
      });
      return;
    }
    try {
      if (editMode) {
        await updateCustomer(formData);
        Swal.fire({
          icon: "success",
          title: "Updated",
          text: "Customer details have been updated successfully!",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "my-ok-button-class",
          },
        });
      } else {
        await createCustomer(formData);
        Swal.fire({
          icon: "success",
          title: "Created",
          text: "New Customer created successfully!",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "my-ok-button-class",
          },
        });
      }
      setShowModal(false);
      setEditMode(false);
      setFormData({
        customer_name: "",
        customer_man_id: "",
        route: "",
        location: "",
        customer_phone_number: "",
      });
      fetchData();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `An error occurred: ${error.message}`,
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "my-ok-button-class",
        },
      });
    }
  };

  const handleOpenModal = (user = null) => {
    setShowModal(true);
    if (user) {
      setEditMode(true);
      setSelectedUserId(user.customer_id); // Use user_id for update
      setFormData({
        customer_id: user.customer_id,
        customer_man_id: user.customer_man_id,
        customer_name: user.customer_name,
        location: user.location,
        route: user.route,
        customer_phone_number: user.customer_phone_number,
      });
    } else {
      setEditMode(false);
      setFormData({
        customer_name: "",
        customer_man_id: "",
        route: "",
        location: "",
        customer_phone_number: "",
      });
    }
  };

  const handledeleteCustomer = (userId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: "my-confirm-button-class",
        cancelButton: "my-cancel-button-class",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteCustomer(userId);
          fetchData();
          Swal.fire({
            title: "Deleted!",
            text: "Customer Detail Deleted successfully.",
            icon: "success",
            confirmButtonText: "OK",
            customClass: {
              confirmButton: "my-ok-button-class",
            },
          });
        } catch (error) {
          Swal.fire(
            "Error!",
            `An error occurred while deleting: ${error.message}`,
            "error"
          );
        }
      }
    });
  };

  const columns = [
    { field: "customer_man_id", headerName: "Customer Man Id", width: 300 },
    { field: "customer_name", headerName: "Customer Name", width: 300 },
    { field: "location", headerName: "Location", width: 300 },
    { field: "route", headerName: "Route", width: 300 },
    {
      field: "customer_phone_number",
      headerName: "Customer PhoneNumber",
      width: 300,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 392,
      renderCell: (params) => (
        <div style={{ display: "flex", gap: "10px" }}>
          <FaUserEdit
            className="userEdit icon-edit"
            onClick={() => handleOpenModal(params.row)}
          />
          <MdDelete
            className="userEdit icon-edit"
            onClick={() => handledeleteCustomer(params.row.customer_id)}
          />
        </div>
      ),
    },
  ];

  // useEffect(()=>{
  //     if(!localStorage.getItem("userid")){
  //       navigate("/login")
  //     }
  //   },[])
  return (
    <Container fluid>
      <div className="main my-3 " style={{ height: "89vh" }}>
        <div>
          <Row>
            <Col lg="6" md="6" xs="6">
              <div className="page-nav py-3 h5">
                <h3>Customer</h3>
              </div>
            </Col>
            <Col lg="6" md="6" xs="6">
              <div className="text-end py-3">
                <input
                  type="file"
                  accept=".xls,.xlsx"
                  onChange={handleFileUpload}
                  style={{ display: "none" }}
                  ref={fileInputRef}
                  id="upload-file"
                />
                <label htmlFor="upload-file">
                  <Button
                    variant="contained"
                    className="add-new me-2"
                    onClick={() => fileInputRef.current.click()} // Trigger file input on button click
                  >
                    Upload XL
                  </Button>
                </label>

                {/* Submit Button (Visible only when a file is uploaded) */}
                {uploadedFile && (
                  <Button
                    variant="primary"
                    className="add-new me-2"
                    onClick={handleSubmitFile}
                  >
                    Submit
                  </Button>
                )}
                <Button
                  variant="contained"
                  onClick={() => handleOpenModal()}
                  className="add-new"
                >
                  Add New
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div style={{ height: 500, width: "100%" }}>
                <DataGrid
                  rows={userData}
                  columns={columns}
                  loading={loading}
                  autoPageSize
                  getRowId={(row) => row.customer_id}
                  disableSelectionOnClick
                  sx={{
                    "& .MuiDataGrid-columnHeader": {
                      backgroundColor: "#4B0082",
                      color: "white",
                      fontWeight: "bold",
                      "&:hover": {
                        ".MuiSvgIcon-root": {
                          color: "white", // Change the icon color to white on hover
                        },
                      },
                    },
                    "& .MuiDataGrid-columnHeader--alignLeft": {
                      textAlign: "left",
                    },
                    "& .MuiDataGrid-columnHeader--sortable": {
                      cursor: "pointer",
                    },
                    "& .MuiDataGrid-withBorderColor": {
                      borderColor: "#530004",
                    },
                    "& .MuiDataGrid-scrollbar--vertical": {
                      display: "none",
                    },
                  }}
                />
              </div>
            </Col>
          </Row>
          <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>
                {editMode ? "Edit Customer" : "Add New Customer"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <TextField
                label="Customer Man Id"
                name="customer_man_id"
                value={formData.customer_man_id}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Customer Name"
                name="customer_name"
                value={formData.customer_name}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Location"
                name="location"
                value={formData.location}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Route"
                name="route"
                value={formData.route}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Customer PhoneNumber"
                name="customer_phone_number"
                value={formData.customer_phone_number}
                onChange={handleInputChange}
                fullWidth
                margin="normal"
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowModal(false)}
                className="add-new"
              >
                Close
              </Button>
              <Button
                variant="primary"
                onClick={handleSubmit}
                className="add-new"
              >
                {editMode ? "Update" : "Submit"}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </Container>
  );
};

export default Customer;

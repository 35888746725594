// api.js
const API_BASE_URL = "https://apivsagency.barbikan.in/user";
const API_BASE_CUSTOMER = "https://apivsagency.barbikan.in/customer";
// Fetch all users
export const fetchAllUsers = async () => {
    try {
        const response = await fetch(`${API_BASE_URL}/getalluser`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `${localStorage.getItem("token")}`

            },
        });

        if (!response.ok) {
            throw new Error("Failed to fetch data");
        }

        const responseData = await response.json();
        return responseData.users;
    } catch (error) {
        console.error("Error fetching data:", error.message);
        throw error;
    }
};


// Create a new user
export const createUser = async (userData) => {
    try {
        const response = await fetch(`${API_BASE_URL}/createuser`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": ` ${localStorage.getItem("token")}`,  // Authorization with Bearer token
            },
            body: JSON.stringify(userData),
        });

        const responseData = await response.json(); // Parse response data as JSON

        if (response.status !== 200) {
            // If the status is not 200, handle the error case
            if (Array.isArray(responseData.errors)) {
                const errorMessages = responseData.errors.map((err) => err.message).join(', ');
                throw new Error(`${errorMessages}`);
            } else {
                throw new Error(responseData.msg || "An error occurred");
            }
        }

        // Success response handling
        console.log("User created successfully:", responseData.newUser);
        return responseData.newUser;  // Return the newUser data
    } catch (error) {
        console.error("Error creating user:", error.message);
        throw error;  // Re-throw the error to propagate it up the chain
    }
};

// Update an existing user
export const updateUser = async (userId, updatedData) => {
    try {
        const response = await fetch(`${API_BASE_URL}/${userId}/updateuser`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": ` ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify(updatedData),
        });

        if (response.status !== 200) {
            throw new Error("Failed to update user");
        }

        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error("Error updating user:", error.message);
        throw error;
    }
};

// Delete a user
export const deleteUser = async (userId) => {
    try {
        const response = await fetch(`${API_BASE_URL}/${userId}/deleteuser`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Authorization": ` ${localStorage.getItem("token")}`,
            },
        });

        if (response.status !== 200) {
            throw new Error("Failed to delete user");
        }

        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error("Error deleting user:", error.message);
        throw error;
    }
};

export const fetchAllCustomer = async () => {
    try {
        const response = await fetch(`${API_BASE_CUSTOMER}/getallcustomer`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `${localStorage.getItem("token")}`

            },
        });

        if (!response.ok) {
            throw new Error("Failed to fetch data");
        }

        const responseData = await response.json();
        return responseData.data;
    } catch (error) {
        console.error("Error fetching data:", error.message);
        throw error;
    }
};


// Create a new user
export const createCustomer = async (userData) => {
    try {
        const response = await fetch(`${API_BASE_CUSTOMER}/createcustomer`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": ` ${localStorage.getItem("token")}`,  // Authorization with Bearer token
            },
            body: JSON.stringify(userData),
        });

        const responseData = await response.json(); // Parse response data as JSON

        if (response.status !== 200) {
            // If the status is not 200, handle the error case
            if (Array.isArray(responseData.errors)) {
                const errorMessages = responseData.errors.map((err) => err.message).join(', ');
                throw new Error(`${errorMessages}`);
            } else {
                throw new Error(responseData.msg || "An error occurred");
            }
        }

        // Success response handling
        console.log("User created successfully:", responseData.newUser);
        return responseData.newUser;  // Return the newUser data
    } catch (error) {
        console.error("Error creating user:", error.message);
        throw error;  // Re-throw the error to propagate it up the chain
    }
};

// Update an existing user
export const updateCustomer = async (updatedData) => {
    try {
        const response = await fetch(`${API_BASE_CUSTOMER}/updatecustomer`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": ` ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify(updatedData),
        });

        if (response.status !== 200) {
            throw new Error("Failed to update user");
        }

        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error("Error updating user:", error.message);
        throw error;
    }
};

// Delete a user
export const deleteCustomer = async (userId) => {
    try {
        const response = await fetch(`${API_BASE_CUSTOMER}/deletecustomer`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Authorization": ` ${localStorage.getItem("token")}`,
            },
            body: JSON.stringify({ customer_id: userId }),
        });

        if (response.status !== 200) {
            throw new Error("Failed to delete user");
        }

        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error("Error deleting user:", error.message);
        throw error;
    }
};
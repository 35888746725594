import React, { useState, useEffect } from "react";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import { useNavigate, Navigate } from "react-router-dom";
import { Buttons, ClickButton } from "../components/Buttons";
import { PageNav } from "../components/PageTitle";
import { useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { TextInputForm, DropDownUI, Calender } from "../components/Forms";
import moment from "moment/moment";
import { FormControl, Grid, TextField, Typography } from "@mui/material";
import Swal from "sweetalert2";
const product = [
  {
    label: "PEPSI",
    value: "pepsi",
  },
  {
    label: "BRITANIA",
    value: "BRITANIA",
  },
  {
    label: "COLGATE",
    value: "COLGATE",
  },
];
const paymentMethods = [
  { label: "CASH", value: "cash" },
  { label: "GPAY", value: "gpay" },
  { label: "CHECK", value: "check" },
];
const productStatus = [
  { label: "DELIVERED", value: "delivered" },
  { label: "RETURN", value: "return" },
];
const roll = [
  {
    label: "Delivery",
    value: "delivery",
  },
  {
    label: "Sales",
    value: "sales",
  },
];
const personid = [
  {
    label: "Sales@01",
    value: "sales@01",
  },
  {
    label: "Delivery@12",
    value: "delivery@12",
  },
];
const DeliverReEntry = () => {
  const [formData, setFormData] = useState({
    date: "",
    deliveryManLoginId: "",
    productName: "",
    customerId: "",
    payement_method: "",
    product_status: "",
    amount_paid: "",
  });
  const [editTabOpen, setEditTabOpen] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [case_in_amount, setCaseInAmount] = useState(0);
  console.log("formData", formData);
  const [cleared, setCleared] = useState(false);
  const handleAmountChange = (e) => {
    const value = e.target.value;
  
    if (!cleared) {
      // First time it will clear the input
      setFormData({ ...formData, amount_paid: '' });
      setCleared(true); // Set cleared to true after initial clear
    } else {
      // After clearing, handle the input normally
      setFormData({ ...formData, amount_paid: value });
    }
  };
  const handleChange = (e, fieldName) => {
    const value = e.target ? e.target.value : e.value;

    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log("Changing:", name, "New value:", value);  // Debugging line
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const setLabel = (value, field) => {
    setFormData({
      ...formData,
      [field]: moment(value).format("YYYY-MM-DD"), // Update the specified field in formData with the provided value
    });
  };
  const [personId, setPersonId] = useState([]);
  console.log("personId", personId);
  const [customerId, setCustomerID] = useState([]);
  console.log("customerId", customerId);

  const [loading, setLoading] = useState(true);
  const fetchData = async () => {
    console.log("kannan");
    try {
      const response = await fetch(
        "https://apivsagency.barbikan.in/user/filterbyrole?role=deliveryagent",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${localStorage.getItem("token")}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const responseData = await response.json();
      console.log("responseData", responseData);
      setLoading(false);
      if (responseData.status === 200) {
        setPersonId(responseData.users);
      } else {
        throw new Error(responseData.msg);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error.message);
    }
  };
  const fetchCustomer = async () => {
    console.log("kannan");
    try {
      const response = await fetch(
        "https://apivsagency.barbikan.in/delivery/customercodeget",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${localStorage.getItem("token")}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const responseData = await response.json();
      console.log("responseData", responseData);
      setLoading(false);
      if (responseData.status === 200) {
        setCustomerID(responseData.uniqueCustomerCode);
      } else {
        throw new Error(responseData.msg);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error.message);
    }
  };
  useEffect(() => {
    fetchData(); // Call fetchData directly in useEffect
    fetchCustomer();
  }, [formData.payement_method]);
  const [deliveryData, setDeliveryData] = useState({
    casein: [
      { qty: 0, amount: 500, denomination: 0 },
      { qty: 0, amount: 200, denomination: 0 },
      { qty: 0, amount: 100, denomination: 0 },
      { qty: 0, amount: 50, denomination: 0 },
      { qty: 0, amount: 20, denomination: 0 },
      { qty: 0, amount: 10, denomination: 0 },
      { qty: 0, amount: 5, denomination: 0 },
      { qty: 0, amount: 2, denomination: 0 },
      { qty: 0, amount: 1, denomination: 0 },
    ],
    caseout: [
      { qty: 0, amount: 500, denomination: 0 },
      { qty: 0, amount: 200, denomination: 0 },
      { qty: 0, amount: 100, denomination: 0 },
      { qty: 0, amount: 50, denomination: 0 },
      { qty: 0, amount: 20, denomination: 0 },
      { qty: 0, amount: 10, denomination: 0 },
      { qty: 0, amount: 5, denomination: 0 },
      { qty: 0, amount: 2, denomination: 0 },
      { qty: 0, amount: 1, denomination: 0 },
    ],
  });

  console.log("deliveryData", deliveryData);
  console.log("case_in_amount", case_in_amount);
  const fetchDataReEntryDelivery = async () => {
    console.log("kannan");
    try {
      const response = await fetch(
        "https://apivsagency.barbikan.in/appdelivery/deliveryagentcorrectionget",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({
            date: formData.date,
            deliveryManLoginId: formData.deliveryManLoginId,
            productName: formData.productName,
            customercode: formData.customerId,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }
      const responseData = await response.json();
      console.log("responseData", responseData);
      setLoading(false);
      if (responseData.status === 200) {
        setDeliveryData(responseData.delivery);
        setFormData({
          ...formData,
          amount_paid: responseData.delivery.amountpaid,
        })
      } else {
        throw new Error(responseData.msg);
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error.message);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    await fetchDataReEntryDelivery();
    setEditTabOpen(true);
  };
  const handleupdate = async () => {
    // Confirmation prompt using Swal
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, Update it!",
      cancelButtonText: "Cancel",
      customClass: {
        confirmButton: "my-confirm-button-class",
        cancelButton: "my-cancel-button-class",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          // Call the API with PUT request
          const response = await fetch(
            "https://apivsagency.barbikan.in/appdelivery/correctiondeliveryandsalesman",
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
                Authorization: `${localStorage.getItem("token")}`,
              },
              body: JSON.stringify({
                delivery_id: deliveryData.deliveryid,
                product_name: deliveryData.productname,
                qty: deliveryData.qty,
                amount: deliveryData.amount,
                case_in: deliveryData.casein,
                case_out: deliveryData.caseout,
                payment_method: formData.payement_method,
                product_status: formData.product_status,
                amount_paid:
                  formData.payement_method === "cash"
                    ? case_in_amount
                    : formData.amount_paid,
              }),
            }
          );
          console.log("swal resonse", response);
          // Handle API response
          if (response.status !== 200) {
            throw new Error("Failed to update delivery");
          }

          const responseData = await response.json();
          console.log("responseData", responseData);
          setLoading(false);

          // Check response status
          if (responseData.status === 200) {
            setDeliveryData(responseData.delivery);

            // Success notification
            Swal.fire({
              title: "Updated!",
              text: "Delivery updated successfully.",
              icon: "success",
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "my-ok-button-class",
              },
            });
            setDeliveryData({
              casein: [
                { qty: 0, amount: 500, denomination: 500 },
                { qty: 0, amount: 200, denomination: 200 },
                { qty: 0, amount: 100, denomination: 100 },
                { qty: 0, amount: 50, denomination: 50 },
                { qty: 0, amount: 20, denomination: 20 },
                { qty: 0, amount: 10, denomination: 10 },
                { qty: 0, amount: 5, denomination: 5 },
                { qty: 0, amount: 2, denomination: 2 },
                { qty: 0, amount: 1, denomination: 1 },
              ],
              caseout: [
                { qty: 0, amount: 500, denomination: 500 },
                { qty: 0, amount: 200, denomination: 200 },
                { qty: 0, amount: 100, denomination: 100 },
                { qty: 0, amount: 50, denomination: 50 },
                { qty: 0, amount: 20, denomination: 20 },
                { qty: 0, amount: 10, denomination: 10 },
                { qty: 0, amount: 5, denomination: 5 },
                { qty: 0, amount: 2, denomination: 2 },
                { qty: 0, amount: 1, denomination: 1 },
              ],
            });
            setCaseInAmount(0);
          } else {
            throw new Error(responseData.msg);
          }
        } catch (error) {
          // Error notification
          setLoading(false);
          Swal.fire("Error!", `An error occurred: ${error.message}`, "error");
        }
      }
    });
  };
  console.log("payment method", paymentMethod);
  const handleChangecaseinout = (type, index) => (event) => {
    const { value } = event.target;

    // Validate the input value
    const numericValue = Number(value);

    // Check if the value is a number and not NaN
    if (!isNaN(numericValue) && value.trim() !== "") {
      setDeliveryData((prevData) => {
        const updatedData = { ...prevData };
        updatedData[type][index].qty = numericValue; // Set the numeric value
        return updatedData;
      });
    } else {
      // Optionally, handle invalid input
      // You could also reset to 0 or keep the previous value.
      // For example, reset to 0:
      setDeliveryData((prevData) => {
        const updatedData = { ...prevData };
        updatedData[type][index].qty = 0; // Reset to 0 if invalid input
        return updatedData;
      });
    }
  };
  //   const handleAmountPaidChange = (event) => {
  //     const { value } = event.target;

  //     // Ensure only numeric input is allowed
  //     const numericValue = isNaN(Number(value)) || value.trim() === '' ? 0 : Number(value);

  //     setDeliveryData((prevData) => ({
  //       ...prevData,
  //       amountpaid: numericValue, // Update the amountpaid field
  //     }));
  //   };

  useEffect(() => {
    const total = deliveryData.casein.reduce((sum, item) => {
      return sum + item.amount * item.qty;
    }, 0);

    setCaseInAmount(total);
  }, [deliveryData]);
  return (
    <div>
      <div>
        <Container fluid>
          <div className="main my-3">
            <Row>
              <Col lg="12" md="12" xs="12">
                <div className="page-nav py-3 h5">DeliveryManReEntry</div>
              </Col>
              <Col lg="3" md="6" xs="12" className="py-3">
                <div className="w-100">
                  <Calender
                    setLabel={(date) => setLabel(date, "date")}
                    selectedDate={formData.date}
                    calenderlabel="Date"
                  />
                </div>
              </Col>
              <Col lg="3" md="6" xs="12" className="py-3">
                <div className="w-100">
                  <DropDownUI
                    optionlist={product}
                    placeholder="Product"
                    labelname="Product"
                    name="productName"
                    value={formData.productName}
                    onChange={(updatedFormData) =>
                      setFormData({
                        ...formData,
                        productName: updatedFormData.productName,
                      })
                    }
                  ></DropDownUI>
                </div>
              </Col>

              <Col lg="3" md="6" xs="12" className="py-3">
                <div className="w-100">
                  <DropDownUI
                    optionlist={personId.map((user) => ({
                      value: user.login_id,
                      label: user.login_id,
                    }))}
                    placeholder="Person Id"
                    labelname="Person Id"
                    name="deliveryManLoginId"
                    value={formData.deliveryManLoginId}
                    onChange={(updatedFormData) =>
                      setFormData({
                        ...formData,
                        deliveryManLoginId: updatedFormData.deliveryManLoginId,
                      })
                    }
                  ></DropDownUI>
                </div>
              </Col>
              <Col lg="3" md="6" xs="12" className="py-3">
                <div className="w-100">
                  <DropDownUI
                    optionlist={customerId.map((user) => ({
                      value: user,
                      label: user,
                    }))}
                    placeholder="Customer Code"
                    labelname="Customer Code"
                    name="customerId"
                    value={formData.customerId}
                    onChange={(updatedFormData) =>
                      setFormData({
                        ...formData,
                        customerId: updatedFormData.customerId,
                      })
                    }
                  ></DropDownUI>
                </div>
              </Col>
              <Col
                lg="12"
                md="12"
                xs="12"
                className="align-self-center py-3 text-end"
              >
                <div className="d-flex mt-4 justify-content-end">
                  <div className="px-2">
                    <ClickButton
                      onClick={handleSubmit}
                      label={<>SUBMIT</>}
                      className="create-btn "
                    ></ClickButton>
                  </div>
                </div>
              </Col>
            </Row>
            {editTabOpen && (
              <Row>
                <Col lg="2" md="6" xs="12" className="py-3">
                  <div className="w-100">
                    <DropDownUI
                      optionlist={paymentMethods}
                      placeholder="PaymentMethod"
                      labelname="paymentmethod"
                      name="payement_method"
                      value={
                        formData.payement_method 
                      } // Bind the state value
                      onChange={(updatedFormData) =>
                        setFormData({
                          ...formData,
                          payement_method: updatedFormData.payement_method,
                        })
                      }
                    />
                  </div>
                </Col>

                <Col lg="2" md="6" xs="12" className="py-3">
                  <div className="w-100">
                    <DropDownUI
                      optionlist={productStatus}
                      placeholder="ProductStatus"
                      labelname="ProductStatus"
                      name="product_status"
                      value={
                        formData.product_status 
                      } // Bind the state value
                      onChange={(updatedFormData) =>
                        setFormData({
                          ...formData,
                          product_status: updatedFormData.product_status,
                        })
                      }
                    />
                  </div>
                </Col>
              </Row>
            )}
            {editTabOpen && formData.payement_method==="cash"&& (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6">Case In</Typography>
                  <Grid container spacing={2}>
                    {deliveryData.casein.map((item, index) => (
                      <Grid item xs={12} sm={12} key={`casein-${index}`}>
                        <FormControl fullWidth margin="normal">
                          <TextField
                            label={`Rupee ${item.amount} `}
                            value={item.qty} // Display the quantity of each denomination
                            onChange={handleChangecaseinout("casein", index)} // Handle change event
                            variant="outlined"
                            color="secondary" // Adjust color as needed
                            sx={{ color: "#777" }} // Optional: for custom color
                          />
                        </FormControl>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                {/* Case Out on the left side */}
                <Grid item xs={12} sm={6}>
                  <Typography variant="h6">Case Out</Typography>
                  <Grid container spacing={2}>
                    {deliveryData.caseout.map((item, index) => (
                      <Grid item xs={12} sm={12} key={`caseout-${index}`}>
                        <FormControl fullWidth margin="normal">
                          <TextField
                            label={`Rupee: ${item.amount}`}
                            value={item.qty} // Display the quantity of each denomination
                            onChange={handleChangecaseinout("caseout", index)} // Handle change event
                            variant="outlined"
                            color="secondary" // Adjust color as needed
                            sx={{ color: "#777" }} // Optional: for custom color
                          />
                        </FormControl>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>

                {/* Case In on the right side */}
              </Grid>
            )}
            {editTabOpen && (
              <Row className="align-items-center pt-3" style={{marginTop:"80px"}}>
               
                <Col sm={4}>
                  <TextField
                    label="Total Amount"
                    value={deliveryData.amount ? deliveryData.amount : 0} // Display the total amount
                    variant="outlined"
                    color="secondary"
                    sx={{ color: "#777", width: "100%" }} // Full width inside the Col
                  />
                </Col>

               
                <Col sm={4}>
                  <TextField
                    label="Amount Paid"
                    value={formData.amount_paid }
                    onChange={handleAmountChange}
                    variant="outlined"
                    color="secondary"
                    sx={{ color: "#777", width: "100%" }} // Full width inside the Col
                  />
                </Col>
                <Col sm={4}>
                  <TextField
                    label="Remaining Balance"
                    value={deliveryData.remainingbalance || 0} // Display the amount paid
                    variant="outlined"
                    color="secondary"
                    sx={{ color: "#777", width: "100%" }} // Full width inside the Col
                  />
                </Col>
                {/* Update Button */}
                <Col lg={12} className="d-flex justify-content-end py-3">
                  <ClickButton
                    onClick={handleupdate}
                    label="Update"
                    className="create-btn"
                  />
                </Col>
              </Row>
            )}
          </div>
        </Container>
      </div>
    </div>
  );
};

export default DeliverReEntry;

import React, { useState, useEffect } from 'react'
import { Table, Button, Dropdown } from 'react-bootstrap'
import { BiDotsVerticalRounded } from "react-icons/bi";
import { useNavigate } from 'react-router-dom';
import { FaUserEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import "../components/Table.css"
const TableUI = ({ headers, body, style, type, rowData, planViewAction, onClick, onDelete, handleEditClick, pageview }) => {
    return (
        <>
            <Table responsive="md" style={style}>
                <thead>
                    <tr>
                        {headers.map((header, index) => (
                            <th key={index}>{header}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {body.map((rowData, rowIndex) => (
                        <tr key={rowIndex} >
                            {type === "USER" && (
                                <>
                                    <td>{rowData.id}</td>
                                    <td>{rowData.role}</td>
                                    <td>{rowData.type}</td>
                                    <td>{rowData.username}</td>
                                    <td>{rowData.password}</td>
                                    <td className=''>
                                        <FaUserEdit className='userEdit icon-edit' />
                                        <MdDelete className='userEdit icon-edit' />
                                    </td>
                                </>
                            )}
                            {type === "misupload" && (
                                <>
                                    <td>{rowData.id}</td>
                                    <td>{rowData.type}</td>
                                    <td>{rowData.salesman}</td>
                                    <td>{rowData.phoneno}</td>
                                    <td>{rowData.password}</td>
                                    <td className=''>
                                        <FaUserEdit className='userEdit icon-edit' />
                                        <MdDelete className='userEdit icon-edit' />
                                    </td>
                                </>
                            )}
                            {type === "missalesman" && (
                                <>
                                    <td>{rowData.id}</td>
                                    <td>{rowData.type}</td>
                                    <td>{rowData.beatname}</td>
                                    <td>{rowData.customername}</td>
                                    <td>{rowData.overamount}</td>
                                    <td>{rowData.collectionamount}</td>
                                    <td>{rowData.pendingamount}</td>
                                    <td className=''>
                                        <FaUserEdit className='userEdit icon-edit' />
                                        <MdDelete className='userEdit icon-edit' />
                                    </td>
                                </>
                            )}
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    );
};
export { TableUI }